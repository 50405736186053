import { jwtDecode } from 'jwt-decode';

const isTokenValid = (token) => {
    try {
        const decoded = jwtDecode(token);
        return decoded.exp * 1000 > Date.now();
    } catch (error) {
        return false;
    }
};

const decodeToken = (token) => {
    try {
        const decoded = jwtDecode(token);
        return decoded;
    } catch (error) {
        return null;
    }
};

const getToken = () => {
    return localStorage.getItem('stochat_token');
};

const getIdentity = () => {
    const token = getToken();
    if (token) {
        const decoded = decodeToken(token);
        const identiy = { user_uuid: decoded.user_uuid, account_uuid: decoded.account_uuid, companies: decoded.companies };
        return identiy;
    } else {
        return null;
    }
};

export { isTokenValid, getIdentity };