import React from 'react';
import { TableRow, TableCell } from '@mui/material';

function formatName(name) {
    // convert underscores to spaces
    let formattedName = name.replace(/_/g, ' ');
    // capitalize first letter of each word
    formattedName = formattedName.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) + ":";
    return formattedName;
}

function formatValue(value) {
    // if value is a string that looks like an array, turn it into an array
    if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        value = JSON.parse(value);
    }

    // if value is an array, turn it into an unordered list with key/value pairs
    if (Array.isArray(value)) {
        console.log("FOUND AN ARRAY!!");
        return (
            <ul>
                {value.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    }

    return value;
}

function ChatGradeItem({ chat, name, value }) {
    return (
        <>
        <div>
            <h4>{formatName(name)}</h4>
            <div>
                {formatValue(value)}
            </div>
        </div>
        </>
    );
}

export default ChatGradeItem;