import React, { createContext, useState, useContext, useEffect } from 'react';
import { isTokenValid, getIdentity } from '../utils/jwt';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem('stochat_token'));
    const [userDetails, setUserDetails] = useState(null);
    const [loggedOutDueToInactivity, setLoggedOutDueToInactivity] = useState(false);

    const login = (token) => {
        localStorage.setItem('stochat_token', token);
        const decoded = getIdentity();
        setUserDetails(decoded);
        setAuthenticated(true);
        setLoggedOutDueToInactivity(false);
    };

    const logout = (dueToInactivity = false) => {
        localStorage.removeItem('stochat_token');
        setAuthenticated(false);
        setUserDetails(null);
        setLoggedOutDueToInactivity(dueToInactivity);
        clearInterval(tokenCheckInterval); // Clear the interval on logout
    };

    const checkAuthentication = (intervalCheck = false) => {
        const token = localStorage.getItem('stochat_token');
        if (token && isTokenValid(token)) {
            if(!userDetails){
                setAuthenticated(true);
                const userDetails = getIdentity();
                //console.log(userDetails);
                setUserDetails(userDetails);
                //console.log("Auth is good");
            }
        } else {
            console.log("Not authed");
            if(isAuthenticated) {
                logout(intervalCheck);
            }
        }
    };

    let tokenCheckInterval; // Define a variable to store the interval ID

    useEffect(() => {
        checkAuthentication();

        // Set up the interval
        tokenCheckInterval = setInterval(() => {
            checkAuthentication(true);
        }, 1800000); // Check every 30 minutes // seconds (30000 ms)

        // Clear the interval on component unmount
        return () => clearInterval(tokenCheckInterval);
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, userDetails, loggedOutDueToInactivity, setAuthenticated, checkAuthentication }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
