import React, { useState, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ListIcon from '@mui/icons-material/List';
import ChatIcon from '@mui/icons-material/Chat';
import GradeIcon from '@mui/icons-material/Grade';

import ChatDrawer from './ChatDrawer';
import ChatDetail from './ChatDetail';
import ChatMeta from './ChatMeta';
import { Routes, Route } from 'react-router-dom';
import CompanySettings from './CompanySettings';
import FacilityImages from './FacilityImages';
import ChatGrade from './ChatGrade';
import ChatStats from './ChatStats';
import { enqueueSnackbar } from 'notistack';

const ChatSection = ({ chats, setChats, selectedChat, onSelectChat, unreadMessages, handleSendMessage, selectedCompany, setUnreadMessages, messages, setSelectedChat }) => {
  
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [secondMobileDrawerOpen, setSecondMobileDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [bottomNavValue, setBottomNavValue] = useState(1);
  
  // This state will now control the drawer's open state
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerWidth = isMobile ? (mobileDrawerOpen ? '100vw' : 0) : (drawerOpen ? 240 : 65); // Adjust this width according to your mini variant width

    const toggleAI = async (chatUUID, aiState) => {
        // Implement the logic to toggle the AI state here
        console.log(`Toggling AI for chat ${chatUUID} to ${aiState}`);
        // make an axios put request to the server to toggle the AI state
        try {
          const response = await fetch(process.env.REACT_APP_CHAT_SERVER_URL + '/chats/toggle-ai/' + chatUUID + '/' + aiState, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({  }),
          });
    
          console.log(response);
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }

          // Update chats array and selectedChat state
          setChats(currentChats => currentChats.map(chat => {
              if (chat.chatUUID === chatUUID) {
                console.log("WE FOUND THE MATCH");
                console.log(chat.chatUUID);
                console.log(chatUUID);
                console.log(aiState);
                  return { ...chat, ai_enabled: aiState }; // Update AI state in the chats array
              }
              return chat;
          }));

          console.log(selectedChat);
          console.log("^^^^ selectedChat ^^^^");
          if (selectedChat && selectedChat.chatUUID === chatUUID) {
              setSelectedChat(prev => ({ ...prev, ai_enabled: aiState })); // Update AI state in selectedChat
          }
    
          //alert('Chat AI status updated successfully!');
          enqueueSnackbar('AI status updated successfully', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' }});
        } catch (error) {
          console.error('There has been a problem with your fetch operation:', error);
          //alert('Failed to update settings. Please try again later.');
          enqueueSnackbar('Failed to update AI status', { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'right' }});
        }


    };

    // useEffect w/ dependency on selectedChat to close the drawer when a chat is selected (if mobile)
    useEffect(() => {
      if (isMobile && selectedChat) {
        setMobileDrawerOpen(false);
        setBottomNavValue(1);
      }
    }, [selectedChat, isMobile]);

  
    return (
      <Box sx={{ 
        display: 'flex', 
        backgroundColor: 'rgb(250, 250, 251)', 
        pt: "65px",
        flexDirection: isMobile ? 'column' : 'row' // Stack vertically on mobile
      }}>      
      <ChatDrawer
        chats={chats}
        onSelectChat={onSelectChat}
        activeChat={selectedChat}
        unreadMessages={unreadMessages}
        drawerOpen={isMobile ? mobileDrawerOpen : drawerOpen}
        setDrawerOpen={isMobile ? setMobileDrawerOpen : setDrawerOpen} // Passing the state handler to the child
        setUnreadMessages={setUnreadMessages}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          overflowX: 'hidden' // Prevents horizontal scrolling on mobile
        }}
        isMobile={isMobile}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, display: isMobile && secondMobileDrawerOpen ? 'none' : 'block', paddingBottom: isMobile ? '80px' : 3 }} >
          <Routes>
            <Route path="/" element={selectedChat && (
                <ChatDetail
                key={chats[selectedChat.chatUUID]?.messages.length}
                chat={selectedChat}
                onSend={handleSendMessage}
                onToggleAI={toggleAI}
                />
            )} />
            <Route path="company-settings"  element={<CompanySettings selectedCompany={selectedCompany} companyUUID={selectedCompany.companyUUID} />} />
            <Route path="facility-images"  element={<FacilityImages companyUUID={selectedChat?.companyUUID} />} />
            <Route path="chat-stats"  element={<ChatStats companyUUID={selectedCompany} />} />
          </Routes>
      </Box>
      <Routes>
        <Route path="/" element={selectedChat && (
        <Box sx={{ width: isMobile ? '100vw' : '320px', p: isMobile ? 3 : 0, display: isMobile && !secondMobileDrawerOpen ? 'none' : 'block' }}>
          <ChatMeta
            chat={selectedChat}
            />
            <ChatGrade chat={selectedChat} /></Box>
        )} />
      </Routes>
      {isMobile ? (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={999}>
        <BottomNavigation
          showLabels
          value={bottomNavValue}
          onChange={(event, newValue) => {
            setBottomNavValue(newValue);
            switch (newValue) {
              case 0:
                setMobileDrawerOpen(true);
                setSecondMobileDrawerOpen(false);
                break;
              case 1:
                setMobileDrawerOpen(false);
                setSecondMobileDrawerOpen(false);
                break;
              case 2:
                setMobileDrawerOpen(false);
                setSecondMobileDrawerOpen(true);
                break;
              default:
                setMobileDrawerOpen(false);
                setSecondMobileDrawerOpen(false);
            }

          }}
        >
          <BottomNavigationAction label="Chat List" icon={<ListIcon />} />
          <BottomNavigationAction label="Chat" icon={<ChatIcon />} />
          <BottomNavigationAction label="Chat Grading" icon={<GradeIcon />} />
        </BottomNavigation>
      </Paper>
      ) : null}
    </Box>
  );
}

export default ChatSection;
