import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Paper, Grid } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function ChatStats({ companyUUID }) {

  const [chatStats, setChatStats] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_CHAT_SERVER_URL + '/chat-stats', {
          params: { companyUUID }
        });
        console.log("Got chat stats: ", response.data);
        setChatStats(response.data);
      } catch (error) {
        console.error('Error fetching chat stats', error);
        // Handle error appropriately, could set an error state and display to user
      }
    };

    fetchData();
  }, [companyUUID]);

  // Function to format header names
  const formatHeaderName = (headerName) => {
    return headerName.replace(/_/g, ' ')
                     .replace(/\b\w/g, letter => letter.toUpperCase());
  };

  // Extracting and formatting table headers
  const tableHeaders = chatStats && chatStats.length > 0
  ? Object.keys(chatStats[0]).map(key => formatHeaderName(key))
  : [];

  const sortedChatStats = chatStats ? [...chatStats].sort((a, b) => b.chat_day.localeCompare(a.chat_day)) : [];

  const totalChatsVsAvgGradeData = chatStats ? {
    labels: chatStats.map(stat => stat.chat_day),
    datasets: [
      {
        label: 'Total Chats',
        data: chatStats.map(stat => stat.chats),
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        yAxisID: 'y1',
      },
      {
        label: 'Total Cost',
        data: chatStats.map(stat => stat.cost),
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        yAxisID: 'y2',
      },
    ]
  } : {};
  
  const totalChatsVsAvgGradeOptions = {
    plugins: {
      legend: {
        display: false, // This line hides the legend
      }, title: {
        display: true,
        text: 'Total Chats vs. Average Grade',
        font: {
          size: 18
        }
      },
    }, scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Total Chats'
        }
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show
        },
        title: {
          display: true,
          text: 'Total Cost'
        }
      },
    },
  };

  const avgMsgVsFuncCallsData = chatStats ? {
    labels: chatStats.map(stat => stat.chat_day),
    datasets: [
      {
        label: 'Average Messages Per Chat',
        data: chatStats.map(stat => parseFloat(stat.avg_messages_per_chat)),
        borderColor: 'green',
        backgroundColor: 'rgba(0, 128, 0, 0.5)',
        yAxisID: 'y1',
      },
      {
        label: 'Average Function Calls Per Chat',
        data: chatStats.map(stat => parseFloat(stat.avg_function_calls_per_chat)),
        borderColor: 'orange',
        backgroundColor: 'rgba(255, 165, 0, 0.5)',
        yAxisID: 'y2',
      },
    ]
  } : {};
  
  const avgMsgVsFuncCallsOptions = {
    plugins: {
      legend: {
        display: false, // This line hides the legend
      }, title: {
        display: true,
        text: 'Average Messages vs. Average Function Calls',
        font: {
          size: 18
        }
      },
    }, scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Average Messages'
        }
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Average Function Calls'
        }
      },
    },
  };

  const avgMetricsData = chatStats ? {
    labels: chatStats.map(stat => stat.chat_day),
    datasets: [
      {
        label: 'Average Rapport',
        data: chatStats.map(stat => stat.avg_rapport),
        borderColor: 'purple',
        backgroundColor: 'rgba(128, 0, 128, 0.5)',
        yAxisID: 'y2',
      },
      {
        label: 'Average Satisfaction',
        data: chatStats.map(stat => stat.avg_satisfaction),
        borderColor: 'pink',
        backgroundColor: 'rgba(255, 192, 203, 0.5)',
        yAxisID: 'y2',
      },
      {
        label: 'Average Sentiment (%)',
        data: chatStats.map(stat => stat.avg_sentiment),
        borderColor: 'cyan',
        backgroundColor: 'rgba(0, 255, 255, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Customer Task Completed Rate (%)',
        data: chatStats.map(stat => stat.customer_task_completed_rate),
        borderColor: 'gold',
        backgroundColor: 'rgba(255, 215, 0, 0.5)',
        yAxisID: 'y',
      },
    ]
  } : {};
  
  const avgMetricsOptions = {
    plugins: {
      legend: {
        display: false, // This line hides the legend
      }, title: {
        display: true,
        text: 'Agent Performance Metrics',
        font: {
          size: 18
        }
      }, tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              // if label contains a % sign, format as a percentage
              if (label.includes('%')) {
                label += context.parsed.y * 100 + '%';
              } else {
                label += context.parsed.y;
              }
              
            }
            return label;
          }
        }
      }
    }, scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        stacked: true,
        title: {
          display: true,
          text: 'Percentage (%)'
        },
        ticks: {
          callback: function(value, index, ticks) {
            return (value * 100) + '%';
          }
        }
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Average Rating (0-10)'
        }
      },
    }
  };
  

  const renderCharts = chatStats && chatStats.length > 0;

  return (
    <Box p={3}>
      {renderCharts && (
        <Grid container spacing={3} sx={{ marginBottom: '20px'}}>
        {/* Chart 1: Total Chats vs. Average Grade */}
        <Grid item xs={4}>
          <Paper elevation={0} style={{ padding: '10px' }}>
            <Line data={totalChatsVsAvgGradeData} options={totalChatsVsAvgGradeOptions} />
          </Paper>
        </Grid>
        

        {/* Chart 2: Avg Messages vs. Avg Function Calls */}
        <Grid item xs={4}>
          <Paper elevation={0} style={{ padding: '10px' }}>
            <Line data={avgMsgVsFuncCallsData} options={avgMsgVsFuncCallsOptions} />
          </Paper>
        </Grid>

        {/* Chart 3: Avg Rapport, Satisfaction, etc. */}
        <Grid item xs={4}>
          <Paper elevation={0} style={{ padding: '10px' }}>
            <Line data={avgMetricsData} options={avgMetricsOptions} />
          </Paper>
        </Grid>
      </Grid>
      )}

      <Paper elevation={0} style={{ padding: '20px' }}>
      <h4>Chat Stats:</h4>
      {sortedChatStats && (
        <Table size="small">
          <TableHead>
            <TableRow>
              {tableHeaders.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedChatStats.map((stat, index) => (
              <TableRow key={index}>
                {Object.values(stat).map((value, idx) => (
                  <TableCell key={idx}>{value !== null ? value : 'N/A'}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      </Paper>
    </Box>
  );
}

export default ChatStats;
