import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import ChatMetaItem from './ChatMetaItem';

function ChatMeta({chat}) {
    
    const [chatMeta, setChatMeta] = useState(null);

    useEffect(() => {
        // This function is to fetch chat details from an endpoint
        const fetchChatMeta = async () => {
            if (!chat) return; // If there's no chat selected, don't fetch
                console.log("NOT FETCHING");
            try {
                const response = await fetch(process.env.REACT_APP_CHAT_SERVER_URL + `/meta/${chat.chatUUID}`); // Replace with your actual endpoint
                const data = await response.json();
                setChatMeta(data.metas); // Update state with the fetched chat details
            } catch (error) {
                console.error('Failed to fetch chat details:', error);
            }
        };

        fetchChatMeta();
    }, [chat]);

    return (
        <Box sx={{ width: '300px', mr: '20px' }}>
            <Box sx={{ width: '300px', border: 1, borderRadius: '8px', borderColor: 'rgb(230, 235, 241)', backgroundColor: '#fff', overflow: 'hidden', mt: '24px', p: 3 }}>
                <Table size="small" aria-label="chat meta data">
                    <TableBody>
                {/* Ensure chatMeta is an array before mapping */}
                {chatMeta && chatMeta.length > 0 ? (
                    chatMeta.map((meta, index) => (
                        <ChatMetaItem
                            key={meta.chatUUID + '-' + meta.meta_key + '-' + index}
                            meta={meta}
                            chat={chat}
                            index={index + 1}
                        />
                    ))
                ) : (
                    <div>No meta information available.</div>
                )}
                </TableBody>
                </Table>
            </Box>
        </Box>
    );
}

export default ChatMeta;