import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import UnitGroupCard from './UnitGroupCard';
//import './FacilityCards.css';

function UnitGroupCards({ unit_groups }) {
    return (
        <Box sx={{ overflowX: 'auto' }}>
            <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {unit_groups.map((unit_group, index) => (
                    <Grid item key={index} sx={{ width: '85%', flexShrink: 0, marginRight: '5%' }}>
                        <UnitGroupCard unit_group={unit_group} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default UnitGroupCards;