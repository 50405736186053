// src/hooks/useCompanySubscriptions.js
import { useEffect } from 'react';
import { getPusherInstance } from '../pusher/PusherManager';

const useCompanySubscriptions = (companyUUID, setChats, setUnreadMessages) => {

    useEffect(() => {
        if (!companyUUID) return;

        const pusher = getPusherInstance();
        const companyChannel = pusher.subscribe(`${companyUUID}`);

        // Bind to handle generic company alerts
        companyChannel.bind('company-alert', (info) => {
            console.log("Got a company alert for: ", companyUUID);
            const { key, value } = parseMessage(info.message);

            if (key === 'new_chat') {
                console.log("It's a new chat!");
                handleNewChat(companyUUID, value, setChats, setUnreadMessages, pusher);
            }
        });

        /*companyChannel.bind('new-chat', (newChat) => {
            // Assuming newChat contains the full chat data
            setChats(prevChats => [...prevChats, newChat]);
        });*/

        return () => {
            companyChannel.unbind_all();
            pusher.unsubscribe(`${companyUUID}`);
        };
    }, [companyUUID, setChats, setUnreadMessages]);

    return null;
};

function parseMessage(message) {
    const [key, value] = message.split(/:(.+)/);
    return { key, value };
}

function handleNewChat(companyUUID, chatUUID, setChats, setUnreadMessages, pusher) {
    console.log("Handling new chat for company:", companyUUID, chatUUID);
    markNewChatUnread(chatUUID, setUnreadMessages);

    fetch(`${process.env.REACT_APP_CHAT_SERVER_URL}/chats/${companyUUID}?destination=web`)
        .then(response => response.json())
        .then(data => {
            // Log how many chats were fetched
            console.log("Fetched chats for company:", companyUUID, data.chats.length);
            setChats(data.chats);
            subscribeToChat(chatUUID, setUnreadMessages, pusher);
        })
        .catch(error => console.error("Failed to fetch chat data:", error));
}

function subscribeToChat(chatUUID, setUnreadMessages, pusher) {
    const chatChannel = pusher.subscribe(chatUUID);

    chatChannel.bind('message-observer', (newMessage) => {
        console.log("Received message for chat:", chatUUID, newMessage);
        updateChatMessages(chatUUID, newMessage, setUnreadMessages);
    });

    // Define additional event bindings as needed
}

function markNewChatUnread(chatUUID, setUnreadMessages) {
    setUnreadMessages(prevMessages => ({
        ...prevMessages,
        [chatUUID]: 1
    }));
}

function updateChatMessages(chatUUID, newMessage, setUnreadMessages) {
    console.log("Updating chat messages for chat:", chatUUID, newMessage);
    setUnreadMessages(prevMessages => {
        const currentCount = prevMessages[chatUUID] || 0;
        return {
            ...prevMessages,
            [chatUUID]: currentCount + 1  // Increment the count of unread messages
        };
    });
}

export default useCompanySubscriptions;
