// src/hooks/useChatSubscriptions.js
import { useEffect, useRef } from 'react';
import { getPusherInstance } from '../pusher/PusherManager';
import wavFile from '../sounds/escalation.wav';

const useChatSubscriptions = (companyUUID, chats, setChats, setMessages, setUnreadMessages, selectedChat, setSelectedChat) => {
    const chatChannels = useRef({});
    const selectedChatRef = useRef(selectedChat);

    // Update the ref whenever selectedChat changes
    useEffect(() => {
        selectedChatRef.current = selectedChat;
    }, [selectedChat]);

    useEffect(() => {

        const pusher = getPusherInstance();
    
        const handleNewMessage = (chatUUID, newMessage, setChats, selectedChat, setSelectedChat) => {

            setChats(currentChats => currentChats.map(chat => 
                chat.chatUUID === chatUUID 
                    ? {...chat, messages: [...chat.messages, {...newMessage, read: false}]}
                    : chat
            ));

            console.log("Let's have fun comparing!");
            console.log(selectedChatRef.current);
            console.log(chatUUID);
            console.log("Are they the same?", selectedChatRef.current && chatUUID === selectedChatRef.current.chatUUID);

            if (selectedChatRef.current && chatUUID === selectedChatRef.current.chatUUID) {
                console.log("Updating selected chat with new message");
                setSelectedChat({...selectedChatRef.current, messages: [...selectedChatRef.current.messages, newMessage]});                
            }

        };

        // Subscribe to all chats for the given company
        chats.forEach(chat => {
            if (!chatChannels.current[chat.chatUUID]) {
                const chatChannel = pusher.subscribe(`${chat.chatUUID}`);
                chatChannel.bind('message-observer', (newMessage) => {
                    console.log("New message received for chat:", chat.chatUUID, newMessage);

                    // add a uuid to the newMessage
                    newMessage.messageUUID = Math.random().toString(36).substring(7);
                    newMessage.chatUUID = chat.chatUUID;
                    newMessage.sender = newMessage.role;
                    newMessage.message = newMessage.content;
                    // set the chat_timestamp to the current time
                    newMessage.chat_timestamp = new Date().toISOString();
                    // Get rid of the wrong keys
                    delete newMessage.role;
                    delete newMessage.content;

                    handleNewMessage(chat.chatUUID, newMessage, setChats, selectedChat, setSelectedChat);

                    /*
                    // Update message list
                    setMessages(prevMessages => ({
                        ...prevMessages,
                        [chat.chatUUID]: [...(prevMessages[chat.chatUUID] || []), newMessage]
                    }));
                    */

                    // if it's the current active chat, we want its unread count to be 0
                    console.log("I'm your huckleberry");

                    // Update unread messages count unconditionally
                    setUnreadMessages(prevUnreadMessages => ({
                        ...prevUnreadMessages,
                        //[chat.chatUUID]: ((prevUnreadMessages[chat.chatUUID] || 0) + 1)
                        // set the unread count to 0 if the chat is active, otherwise increment it
                        //[chat.chatUUID]: selectedChatRef.current && chat.chatUUID === selectedChatRef.current.chatUUID ? 0 : ((prevUnreadMessages[chat.chatUUID] ? prevUnreadMessages[chat.chatUUID] + 1 : 0))
                        [chat.chatUUID]: selectedChatRef.current && chat.chatUUID === selectedChatRef.current.chatUUID ? 0 : ((prevUnreadMessages[chat.chatUUID] || 0) + 1)
                    }));
                });

                chatChannel.bind('info-observer', (info) => {
                    setChats((prevChats) => {
                      const newChats = [...prevChats];
                      const chatToUpdate = newChats.find((c) => c.chatUUID === chat.chatUUID);
                      if (!chatToUpdate) return newChats; // Exit if chat is not found
                      chatToUpdate[info.key] = info.value;
                      console.log("Updated it");
                      console.log(chatToUpdate);
                      return newChats;
                    });
                  });

                console.log("Setting Escalation Binding");
                chatChannel.bind('escalation-observer', (escalation) => {
                    setChats((prevChats) => {
                      const newChats = [...prevChats];
                      const chatToUpdate = newChats.find((c) => c.chatUUID === chat.chatUUID);
                      if (!chatToUpdate) return newChats; // Exit if chat is not found
                      chatToUpdate.escalated = true;
                      chatToUpdate.escalation_reason = escalation.reason;
                      const audio = new Audio(wavFile);
                      audio.play();
                      console.log("ESCALATING");
                      return newChats;
                    })
                });

                // Store the channel reference
                chatChannels.current[chat.chatUUID] = chatChannel;
                console.log(chatChannels.current);
            }
        });

        // Cleanup: Unsubscribe from all chats when company changes
        return () => {
            Object.keys(chatChannels.current).forEach(chatUUID => {
                const chatChannel = chatChannels.current[chatUUID];
                chatChannel.unbind('message-observer');
                pusher.unsubscribe(`${chatUUID}`);
            });
            chatChannels.current = {};
        };
        // adding 'selectedChat', 'setChats', and 'setSelectedChat' to the dependency array
    }, [companyUUID, chats]); //, setMessages, setUnreadMessages]);  // Dependency on companyUUID to trigger cleanup on company change

    return null;
};

export default useChatSubscriptions;
