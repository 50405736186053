// src/pusher/PusherManager.js
import Pusher from 'pusher-js';

let pusherInstance = null;

export const getPusherInstance = () => {
    if (!pusherInstance) {
        pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            forceTLS: true
        });
    }
    return pusherInstance;
};
