import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { Container, Alert } from '@mui/material';
import { useAuth } from '../context/AuthContext';

const LoginPage = () => {
    const navigate = useNavigate();
    const authContext = useAuth();
    const { login, loggedOutDueToInactivity } = authContext;

    const handleLoginSuccess = (token) => {
        try{
            login(token);
        }catch(err){
            console.log(err);
        }
        navigate('/'); // Redirect to the home page
    };

    console.log(loggedOutDueToInactivity);

    return (
        <Container component="main" maxWidth="s">
            {loggedOutDueToInactivity === true && (
                <Alert variant="filled" severity="warning">
                    You have been logged out due to inactivity!
              </Alert>
            )}
            <LoginForm onLoginSuccess={handleLoginSuccess} />
        </Container>
    );
};

export default LoginPage;
