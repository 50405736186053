import React, { useState } from 'react';
import { Paper, CardContent, Typography, Button, Switch, TextareaAutosize, Box } from '@mui/material';
import { styled } from '@mui/system';
import FacilityCards from './chatComponents/FacilityCards';
import UnitGroupCards from './chatComponents/UnitGroupCards';

const MessageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  backgroundColor: '#fff',
});

const UserMessage = styled(Paper)({
  alignSelf: 'flex-start',
  backgroundColor: 'rgb(250, 250, 251)',
  borderRadius: '10px',
  marginBottom: '10px',
  maxWidth: '500px',
  elevation: 0,
  wordWrap: 'break-word',
  overflow: 'hidden',
});

const AssistantMessage = styled(Paper)({
  alignSelf: 'flex-end',
  backgroundColor: '#f1faff',
  borderRadius: '10px',
  marginBottom: '10px',
  maxWidth: '500px',
  elevation: 0,
  wordWrap: 'break-word',
  overflow: 'hidden',
});

const Textarea = styled(TextareaAutosize)({
  width: '100%',
  marginTop: '10px',
});

 
function ChatDetail({ chat, onSend, onToggleAI }) {
    const [message, setMessage] = useState('');
  
    const handleMessageSend = () => {
      onSend(message);
      setMessage('');
    };

    // toggleAI is called on click and checks the value of the switch that called it to set the AI state
    const toggleAI = (event) => {
      const aiState = event.target.checked ? 0 : 1;
      onToggleAI(chat.chatUUID, aiState);
    };

    const parseMessageText = (messageText) => {
        try {
          const j = JSON.parse(messageText);
            //console.log("Parsed Message Text");
            //console.log(j);
          return j;
        } catch (e) {
          // console.error('Error parsing message text:', e);
          // You can return a fallback or an indication of the error as appropriate
          return { text: messageText };
        }
      };
  
    return (
        <>
      <Box sx={{ border: 1, borderRadius: '8px', borderColor: 'rgb(230, 235, 241)', overflow: 'hidden' }} >
        <MessageContainer>
        {chat.messages.map((msg, index) => {
            const messageContent = parseMessageText(msg.message);
            return messageContent.render_type && messageContent.render_type === "facility_cards" ? 
              // If render_type is "facility_card", render the FacilityCard component
              <FacilityCards key={index} facilities={messageContent.facilities} /> :
              (
                messageContent.render_type && messageContent.render_type === "unit_group_cards" ? 
                <UnitGroupCards key={index} unit_groups={messageContent.unit_groups} /> :
              // Otherwise, render the regular message
              (msg.sender === 'user' ? (
                <UserMessage key={index} elevation={0} >
                  <CardContent>
                    <Typography variant="body2">{msg.message}</Typography>
                  </CardContent>
                </UserMessage>
              ) : (
                <AssistantMessage key={index} elevation={0}>
                  <CardContent>
                    <Typography variant="body2">{msg.message}</Typography>
                  </CardContent>
                </AssistantMessage>
              ))
              )
              })}
        </MessageContainer>
        <Textarea
          minRows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button onClick={handleMessageSend}>Send</Button>
        <div>
          <Switch
            checked={chat.ai_enabled == 0}
            onChange={toggleAI}
            color="primary"
          />
          Disable AI
        </div>
      </Box>
      </>
    );
  }
  
  export default ChatDetail;