import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ChatListItem from './ChatListItem';

function ChatList({ chats =[], onSelectChat, activeChat, unreadMessages={}, setUnreadMessages, isMobile }) {
  const [sortedChats, setSortedChats] = useState([]);

  useEffect(() => {
    if (chats && chats.length > 0) {
      const sorted = chats.slice().sort((a, b) => {
        const aTimestamp = a.messages[a.messages.length - 1]?.chat_timestamp;
        const bTimestamp = b.messages[b.messages.length - 1]?.chat_timestamp;
        return new Date(bTimestamp) - new Date(aTimestamp);
      });
      setSortedChats(sorted);
    } else {
      setSortedChats([]);
    }
  }, [chats]);

  if (!chats) {
    //console.log("Chats are undefined!");
    return null; // or a placeholder indicating no chats available
  }else{
    //console.log("Chats are defined!");
    //console.log(chats);
    //console.log(unreadMessages);
  }

  const handleSelectChat = (chat) => {
    setUnreadMessages({ ...unreadMessages, [chat.chatUUID]: 0 });
    onSelectChat(chat);
  }

  return (
    <List>
      {sortedChats.map((chat, index) => (
        <ChatListItem
        key={chat.chatUUID}
        chat={chat}
        index={index + 1}
        isActive={activeChat ? chat.chatUUID === activeChat.chatUUID : false}
        onClick={handleSelectChat}
        unreadCount={unreadMessages[chat.chatUUID] || 0}
        isMobile={isMobile}
      />
      ))}
    </List>
  );
}

export default ChatList;
