import React from 'react';
import { TableRow, TableCell } from '@mui/material';

function ChatMetaItem({ chat, index, meta }) {
    return (
        <>
        <TableRow>
            <TableCell component="th" scope="row" sx={{fontSize:"11px"}}>
                {meta.meta_key}
            </TableCell>
            <TableCell sx={{fontSize:"11px"}}>
                {meta.meta_value}
            </TableCell>
        </TableRow>
        </>
    );
}

export default ChatMetaItem;