import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FacilityCard from './FacilityCard';
//import './FacilityCards.css';

function FacilityCards({ facilities }) {
    return (
        <Box sx={{ overflowX: 'auto', maxWidth:'800px' }}>
            <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {facilities.map((facility, index) => (
                    <Grid item key={index} sx={{ width: '45%', flexShrink: 0, marginRight: '5%' }}>
                        <FacilityCard facility={facility} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default FacilityCards;