import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Select, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme, useMediaQuery } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AppBarComponent = ({ companies, selectedCompany, handleCompanyChange }) => {

  const { userDetails, logout } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleLogout = () => {
    //handleClose(); // Close the menu
    logout(false); // Call the logout function
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography variant="h6">
          <MuiLink component={RouterLink} to="/" sx={{ color:'#ffffff', textDecoration: 'none' }}><img src="https://assets.storedgefms.com/assets/storedge-logo-circle-bf8abc8ddfa5e7b674ed49341b89d69b891f95f497f70c8ed5afd0c619023180.svg" style={{ height:'50px', marginTop:'10px', height:'40px' }} /></MuiLink>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        {userDetails && userDetails.userUUID === '73896bf0-3f5a-44eb-9482-b721e6522997' && (
        <MuiLink component={RouterLink} to="/chat-stats" sx={{ color:'#ffffff', textDecoration: 'none', mr: '20px' }}>Chat Stats</MuiLink>
        )}
        {userDetails && userDetails.userUUID === '73896bf0-3f5a-44eb-9482-b721e6522997' && (
        <MuiLink component={RouterLink} to="/facility-images" sx={{ color:'#ffffff', textDecoration: 'none', mr: '20px' }}>Facility Images</MuiLink>
        )}
        <MuiLink component={RouterLink} to="/company-settings" sx={{ color:'#ffffff', textDecoration: 'none', mr: '20px' }}>Settings</MuiLink>
        <Select
          value={selectedCompany.uuid}
          onChange={handleCompanyChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ backgroundColor: '#ffffff', borderRadius: '4px', padding: '0px', color: '#000000', fontSize: '12px', height: '40px' }}
        >
          {companies.map((company) => (
            <MenuItem key={company.uuid} value={company.uuid}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
