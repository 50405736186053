import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Box, Button, TextareaAutosize } from '@mui/material';
import { useSnackbar } from 'notistack';
import FileUploadComponent from './FileUploadComponent';

function CompanySettings({ companyUUID, selectedCompany }) {
  const [instructions, setInstructions] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchInstructions = async () => {
      
      try {
        const response = await axios.get(process.env.REACT_APP_CHAT_SERVER_URL + '/company-settings/' + selectedCompany.uuid
        );
        setInstructions(response.data.instructions);
      } catch (error) {
        console.error('Error fetching company settings', error);
        // Handle error appropriately, could set an error state and display to user
      } finally {
        setLoading(false);
      }
    };

    fetchInstructions();
  }, [selectedCompany]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(process.env.REACT_APP_CHAT_SERVER_URL + '/company-settings/' + selectedCompany.uuid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ instructions }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      enqueueSnackbar('Settings updated successfully', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' }});
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      alert('Failed to update settings. Please try again later.');
    }
    setIsSubmitting(false);
  };

  return (
    <>
    <Box p={3}>
        <h4>Company-Specific Instructions:</h4>
      <TextareaAutosize
        minRows={3}
        placeholder="Company-specific instructions"
        style={{ width: '100%' }}
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Saving...' : 'Save Instructions'}
      </Button>
    </Box>
        </>
  );
}

export default CompanySettings;
