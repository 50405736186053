import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
    let { isAuthenticated, checkAuthentication } = useAuth();
    const location = useLocation();

    useEffect(() => {
        checkAuthentication();
    }, [location]);

    if (location.pathname === "/health") {
        return <>Healthy</>
    }

    //isAuthenticated = true;

    //return isAuthenticated ? (isPermitted ? children : <Navigate to="/dashboard" />) : <Navigate to="/login" />;
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
