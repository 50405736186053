import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';

export default function FacilityImages() {
  const [url, setUrl] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // API call to get facility list
    const facilityList = await getFacilityListFromAPI();

    // Scrape website for main locations
    const scrapedData = await scrapeWebsite(url);

    // Process and align data
    const alignedData = alignData(facilityList, scrapedData);

    // Upload images and update database
    alignedData.forEach(async (facility) => {
      const imageUrl = await uploadImageToGoogleCloud(facility.image);
      await updateDatabaseWithImageInfo(facility.uuid, imageUrl);
    });

    // Handle completion
    console.log('Setup completed');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <h2>Absurdly Easy Setup from Storable</h2>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Website URL"
        autoFocus
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
}

// Placeholder functions for API calls and processing
async function getFacilityListFromAPI() {
  // Implement API call
}

async function scrapeWebsite(url) {
  // Implement web scraping logic
}

function alignData(facilityList, scrapedData) {
  // Align and process data
  return []; // Return processed data
}

async function uploadImageToGoogleCloud(image) {
  // Upload image to Google Cloud
}

async function updateDatabaseWithImageInfo(uuid, imageUrl) {
  // Update the database with the image URL and UUID
}


/*import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, TextareaAutosize } from '@mui/material';
import FileUploadComponent from './FileUploadComponent';

function FacilityImages({ companyUUID }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await axios.get('http://localhost:3001/facility-list', {
          params: { companyUUID }
        });
        // set the facilities?
      } catch (error) {
        console.error('Error fetching facilities list', error);
        // Handle error appropriately, could set an error state and display to user
      } finally {
        setLoading(false);
      }
    };

    fetchFacilities();
  }, [companyUUID]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://chat-server-test-bv3rnbltwq-uc.a.run.app/company-settings', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ companyUUID, instructions }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      alert('Settings updated successfully!');
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      alert('Failed to update settings. Please try again later.');
    }
    setIsSubmitting(false);
  };

  return (
    <>
    <Box p={3} sx={{ backgroundColor:"#fff", width:"500px", height:"300px" }}>
        <FileUploadComponent />
        </Box>
        </>
  );
}

export default FacilityImages;
*/