import React, { useState, useEffect } from 'react';
import { Badge, ListItem, Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';

const UserInformation = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledListItem = styled(ListItem)(({ theme, flash, isactive, isEscalated }) => {
  const isMobile = theme.breakpoints.down('sm');

  return {
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    backgroundColor: isactive ? '#1976d220' : isEscalated ? 'orange' : flash ? 'yellow' : 'transparent',
    width: isMobile ? '100%' : '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: isactive ? '#1976d220' : '#78829d20', 
    },
  };
});
  
/*
const StyledListItem = styled(ListItem)({
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#78829d', // or any color you prefer for hover
    },
  });
*/
const Circle = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'bgcolor' && prop !== 'color' && prop !== 'fontSize',
  })((props) => ({
    backgroundColor: props.bgcolor,
    color: props.color,
    marginRight: '10px',
    fontSize: '.7rem',
    fontWeight: 'bold',
    mr: '10px',
  }));

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const UserName = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
});

const UserEmail = styled(Typography)({
  fontSize: '0.8rem',
});

const TimeContainer = styled(Box)({
  textAlign: 'right',
  color: 'grey',
});

const colorPalette = [
    //'#f1416c15',  // Pink
    '#ffc70020',  // Yellow
    '#78829d20',  // Grey
    '#50cd8920',  // Green
    '#3e97ff20',  // Blue
    // ...add more colors as needed
  ];

function ChatListItem({ chat, index, isActive, onClick, unreadCount, isMobile }) {
  const [flash, setFlash] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isEscalated, setIsEscalated] = useState(false);
  let color = colorPalette[index % colorPalette.length];

  if(chat.modality === 'web') {
    color = '#78829d20';
  }else if(chat.modality === 'phone') {
    color = '#3e97ff20';
  }else if(chat.modality === 'sms') {
    color = '#50cd8920';
  }else{
    color = '#ffc70020';
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());  // Update current time every minute
    }, 60000);  // Set to update every 60,000 milliseconds (1 minute)

    return () => clearInterval(intervalId);  // Clear interval on component unmount
  }, []);

  useEffect(() => {
    if (isActive) {
      setFlash(false);
    } else if (unreadCount > 0) {
      setFlash(true);
      const timeoutId = setTimeout(() => setFlash(false), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [unreadCount, isActive]);

  useEffect(() => {
    if (chat.escalated) {
      setIsEscalated(true);
    }
  }, [chat.escalated]);

  const navigate = useNavigate();

  const handleOnClick = () => {
    onClick(chat); 
    navigate('/');
  };

  let displayTime = '';
  const lastMessageTime = chat.messages[chat.messages.length - 1].chat_timestamp;
  let timeSinceLastMessage = currentTime - new Date(lastMessageTime);
  if(timeSinceLastMessage < 0) {
    timeSinceLastMessage = 0;
  }
  const hoursSinceLastMessage = Math.floor(timeSinceLastMessage / 1000 / 60 / 60);
  if (hoursSinceLastMessage < 1) {
    // Display minutes
    const minutes = Math.floor(timeSinceLastMessage / 1000 / 60);
    displayTime = `${minutes}m`;
  }else if (hoursSinceLastMessage < 24) {
    // Display hours
    displayTime = `${hoursSinceLastMessage}h`;
  } else {
    // Display days
    const days = Math.floor(hoursSinceLastMessage / 24);
    displayTime = `${days}d`;
  }

  const textColor = color.slice(0, -2); // less transparent

  return (
    /*<StyledListItem
      onClick={handleOnClick}
      style={{
        backgroundColor: isActive ? '#1976d220' : flash ? 'yellow' : 'transparent',
        transition: 'background-color 0.3s',
        cursor: 'pointer',
        width: '240px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Align items to opposite ends
      }}
    >*/
    <StyledListItem
        onClick={handleOnClick}
        flash={flash ? 1 : 0}
        isactive={isActive ? 1 : 0}
        className={chat.modality}
        isEscalated={isEscalated ? 1 : 0}
    >
      <UserInformation>
        <Circle bgcolor={color} color={textColor} >
          {chat.first_name ? chat.first_name.charAt(0) : 'U' + index }
        </Circle>
        <TextContainer>
          <UserName sx={{ fontSize: '.8rem', fontWeight: 'bold', lineHeight: '.9' }}>{chat.first_name ? chat.first_name : 'Unknown'} {chat.last_name ? chat.last_name : 'User'}</UserName>
          <UserEmail sx={{ fontSize: '.7rem', color: 'rgb(140, 140, 140)' }}>{chat.email_address ? chat.email_address : 'Unknown Email'}</UserEmail>
        </TextContainer>
      </UserInformation>
      <TimeContainer>
        {/* You can replace this with your time since last message logic */}
        <Typography variant="body2" sx={{ color: 'rgb(140, 140, 140)' }}>{ displayTime }</Typography>
        {unreadCount > 0 && (
          <Badge badgeContent={unreadCount} color="primary" sx={{ position:'absolute', left:'50px', bottom:'15px' }} />
        )}
      </TimeContainer>
    </StyledListItem>
  );
}

export default ChatListItem;