import React from 'react';
import { Typography, Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import ChatList from './ChatList';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: '100vw',
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
  },
  [theme.breakpoints.down('sm')]: {
    height:'calc(100vh - 60px)',
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  function ChatDrawer({ chats, onSelectChat, activeChat, unreadMessages, drawerOpen, setDrawerOpen, setUnreadMessages }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleDrawerChange = () => {
      setDrawerOpen(!drawerOpen); // This will now use the handler from the parent component
    };
  
    return (
      <Drawer
        variant="permanent"
        open={drawerOpen}
        sx={{ overflowX: 'hidden', flexShrink: 1 }}
      >
        <Box sx={{ mt: "65px" }}>
          <IconButton onClick={handleDrawerChange} sx={{ display: isMobile ? 'none' : 'block' }}>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <ChatList chats={chats} onSelectChat={onSelectChat} activeChat={activeChat} unreadMessages={unreadMessages} setUnreadMessages={setUnreadMessages} isMobile={isMobile} />
        </Box>
      </Drawer>
    );
  }

export default ChatDrawer;
