// FacilityCard.js
import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

function FacilityCard({ facility }) {
  return (
    <Card>
      <CardMedia
        component="img"
        image={facility.photo}
        alt={facility.facility_name}
        sx={{ maxHeight: '140px' }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" sx={{ fontSize:'14px', fontWeight:'bold' }} component="div">
          {facility.facility_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {facility.street_address}<br />
          {facility.city}, {facility.state} ({facility.distance} miles away)
        </Typography>
        <Box display="flex" alignItems="center" mt={1}>
          <StarIcon color="primary" />
          <Typography variant="subtitle1" component="span">
            5.0
          </Typography>
          <Typography variant="body2" color="text.secondary" component="span" ml={1}>
            (10 reviews)
          </Typography>
        </Box>
        {/* Add other facility details here */}
      </CardContent>
      <CardActions>
        <Button size="small">Contact</Button>
      </CardActions>
    </Card>
  );
}

export default FacilityCard;
