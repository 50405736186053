import React, { useState } from 'react';
import { TextField, Button, Typography, Paper } from '@mui/material';
import axios from 'axios';

const LoginForm = ({ onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const paperStyle = {
        padding: 50,
        height: '50vh',
        width: 450,
        margin: "20px auto",
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.2)',
        minHeight: '370px'
    };

    const mobilePaperStyle = {
        margin: 0,
        height: '100vh',
        width: '100vw',
        boxShadow: 'none',
        paddingTop: '10vh',
        boxSizing: 'border-box'
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log("Making axios request");
            console.log("Checking ENV access:");
            console.log(process.env.REACT_APP_CHAT_SERVER_URL);
            console.log("See if it has the wrong one:");
            console.log(process.env.REACT_APP_CHAT_SERVER_UR);
            console.log("Check other ENVs:");
            console.log(process.env.REACT_APP_PUSHER_APP_KEY);
            console.log(process.env.REACT_APP_PUSHER_APP_CLUSTER);
            
            const response = await axios.post(process.env.REACT_APP_CHAT_SERVER_URL + '/login', { email, password });
            //localStorage.setItem('token', response.data.token);
            console.log("Successfully logged in!");
            console.log(response);
            onLoginSuccess(response.data.stochat_token);
        } catch (err) {
            setError('Invalid credentials');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={3} style={window.innerWidth <= 768 ? mobilePaperStyle : paperStyle}>
                <form onSubmit={handleSubmit} >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="https://www.storable.com/wp-content/uploads/2022/11/Storable-Wordmark-1Color@2x.png" width="150" alt="Storable AI Chat" />
                        <Typography component="h1" variant="h5" style={{ fontSize: '15px', marginTop:'15px' }}>Sign in</Typography>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginBottom:'30px' }}>Sign In</Button>
            </div>
        </form>
            </Paper>
        </div>
    );
};

export default LoginForm;
